import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { Loader } from "@cea/ui-kit";
import { useNavigate, useLocation } from "react-router-dom";

import Appshell from "../Appshell";
import AuthContext from "../../contexts/AuthContext";

import useAuthentication from "./useAuthentication";
import useToken from "./useToken";
import useUser from "./useUser";

const Auth = ({ children }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const { token, updateToken, clearToken, ensureValidToken } = useToken();

  const { handleLogin } = useAuthentication(({ user, token }) => {
    setUser(user);
    updateToken(token);
    navigate(location.pathname === "/login" ? "/" : location.pathname);
  });

  const { user, setUser, fetchUser, loading } = useUser(
    ensureValidToken,
    handleLogin
  );

  const logout = () => {
    clearToken();
    setUser(null);
    navigate("/login");
  };

  useEffect(() => {
    if (token) {
      fetchUser();
    } else {
      navigate("/login");
    }
  }, [token]);

  if (loading) {
    return (
      <Appshell>
        <div className="p-8">
          <Loader tip="Loading" />
        </div>
      </Appshell>
    );
  }

  return (
    <AuthContext.Provider
      value={{
        token,
        user,
        updateToken,
        logout,
        handleLogin,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

Auth.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Auth;
