import jwtDecode from "jwt-decode";

export const isTokenExpired = (token) => {
  try {
    const { exp } = jwtDecode(token);

    return Date.now() >= exp * 1000;
  } catch (error) {
    return true;
  }
};
