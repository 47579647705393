import { useState } from "react";

import { isTokenExpired } from "../../utils";

const useToken = () => {
  const [token, setToken] = useState(localStorage.getItem("admin-token") || "");

  const updateToken = (newToken) => {
    setToken(newToken);
    localStorage.setItem("admin-token", newToken);
  };

  const clearToken = () => {
    setToken("");
    localStorage.removeItem("admin-token");
  };

  const ensureValidToken = async (reAuthenticate) => {
    if (!token || isTokenExpired(token)) {
      const newToken = await reAuthenticate();

      if (newToken) {
        updateToken(newToken);

        return newToken;
      }
      clearToken();

      return null;
    }

    return token;
  };

  return { token, updateToken, clearToken, ensureValidToken };
};

export default useToken;
