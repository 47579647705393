import React, { useContext } from "react";
import PropTypes from "prop-types";
import { Navigate, Outlet } from "react-router-dom";

import AuthContext from "../../contexts/AuthContext";

const ProtectedRoute = ({ redirectPath }) => {
  const { token } = useContext(AuthContext);

  if (!token) {
    return <Navigate to={redirectPath} replace />;
  }

  return <Outlet />;
};

ProtectedRoute.propTypes = {
  redirectPath: PropTypes.string,
};

ProtectedRoute.defaultProps = {
  redirectPath: "/login",
};

export default ProtectedRoute;
