import { useState } from "react";
import { useApolloClient } from "@apollo/client";
import { Notification } from "@cea/ui-kit";

import { FETCH_USER } from "../../queries/user";
import { getNotification } from "../../utils";

const useUser = (ensureValidToken, handleLogin) => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(false);
  const apolloClient = useApolloClient();

  const fetchUser = async () => {
    try {
      setLoading(true);
      const validToken = await ensureValidToken(handleLogin);

      if (!validToken) {
        throw new Error("Token could not be refreshed. Please log in again.");
      }

      const { data } = await apolloClient.query({
        query: FETCH_USER,
        context: {
          headers: { Authorization: `Bearer ${validToken}` },
        },
      });

      setUser(data.me);
    } catch (error) {
      const notificationData = getNotification(error);

      Notification.error(notificationData);
    } finally {
      setLoading(false);
    }
  };

  return { user, setUser, fetchUser, loading };
};

export default useUser;
