import { useMutation } from "@apollo/client";
import { useMsal } from "@azure/msal-react";

import { loginRequest } from "../../msalConfig";
import { O_AUTH_LOGIN } from "../../queries/user";
import { getNotification } from "../../utils";
import { callMsGraph } from "../../utils/MSGraph";

const useAuthentication = (updateUserAndToken) => {
  const { instance: msalInstance } = useMsal();
  const [oAuthLogin] = useMutation(O_AUTH_LOGIN);

  const handleLogin = async () => {
    try {
      const { accessToken } = await msalInstance.loginPopup(loginRequest);
      const userData = await callMsGraph(accessToken);
      const { displayName: name, mail: email } = userData;

      const { data } = await oAuthLogin({
        variables: { name, email: email.toLowerCase(), accessToken },
        notifyOnNetworkStatusChange: true,
      });

      const { user, token } = data.oAuthLogin;

      updateUserAndToken({ user, token });

      return token;
    } catch (error) {
      const notificationData = getNotification(error);

      console.error(notificationData);

      return null;
    }
  };

  return { handleLogin };
};

export default useAuthentication;
